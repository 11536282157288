import { default as _summerqB2PYMJcbnMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/_summer.vue?macro=true";
import { default as indexZLde88niwrMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/about/index.vue?macro=true";
import { default as _91slug_93vKr7rqxqifMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/authors/[slug].vue?macro=true";
import { default as indexKJQSEoVkX7Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/authors/index.vue?macro=true";
import { default as _91slug_93TGmQ4I6LggMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/collections/[slug].vue?macro=true";
import { default as indexBl8T97XIIcMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/collections/index.vue?macro=true";
import { default as index7y0oQHWWy2Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/index.vue?macro=true";
import { default as _91slug_93n1tuWlebkgMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue?macro=true";
import { default as _91slug_93beEx94sPsdMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue?macro=true";
import { default as _91slug_93AY45fWNagEMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue?macro=true";
import { default as _91paragraph_93TNrXPPgihYMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph].vue?macro=true";
import { default as indexrxwKkMBSciMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination/index.vue?macro=true";
import { default as destinationiKE3okghqqMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/destination.vue?macro=true";
import { default as events3bpDW7eymHMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/events.vue?macro=true";
import { default as gpt_45chataLaWTZYSxlMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/gpt-chat.vue?macro=true";
import { default as indexgpm3DJvp8sMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/index.vue?macro=true";
import { default as librarysJwmVaqkWhMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/library.vue?macro=true";
import { default as _91slug_939FvNZBudrgMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/listen/courses/[slug].vue?macro=true";
import { default as indexTrnYxd3Lr3Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/listen/courses/index.vue?macro=true";
import { default as _91slug_93wgYE0o3oDUMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/listen/lectures/[slug].vue?macro=true";
import { default as indexAdKKIGjXrWMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/listen/lectures/index.vue?macro=true";
import { default as _91slug_93ohYZxKiJpUMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/magazine/article/[slug].vue?macro=true";
import { default as _91slug_93crES3DiFTxMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/magazine/release/[slug].vue?macro=true";
import { default as myhistoryX9lnBr8icdMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/myhistory.vue?macro=true";
import { default as indexPARXriA620Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/presentation/index.vue?macro=true";
import { default as privacy_policy_applicationyblHL7DKMaMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/privacy_policy_application.vue?macro=true";
import { default as privacy_policyaojJmNTvbNMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/privacy_policy.vue?macro=true";
import { default as accountSBFBZJkYNPMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/profile/account.vue?macro=true";
import { default as bookmarksZUMSmPF6wKMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/profile/bookmarks.vue?macro=true";
import { default as index3Jbt6KkPUSMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/profile/index.vue?macro=true";
import { default as resultsdKlsa8SGeOMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/profile/results.vue?macro=true";
import { default as index8mPB21t6fKMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/projects/index.vue?macro=true";
import { default as _91slug_93O7q43nFZYWMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/articles/[slug].vue?macro=true";
import { default as indexvv7EXW8DKeMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/articles/index.vue?macro=true";
import { default as _91slug_938YE5rFtCUdMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/biographies/[slug].vue?macro=true";
import { default as indexSQWuJI616gMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/biographies/index.vue?macro=true";
import { default as _91document_93VDf7qRJLn9Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/documents/[slug]/[document].vue?macro=true";
import { default as indexKFzLQQK4YcMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/documents/[slug]/index.vue?macro=true";
import { default as indexsZq7WGNbzJMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/documents/index.vue?macro=true";
import { default as _91slug_935TEMa030PLMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/monuments/[slug].vue?macro=true";
import { default as index9DjXCXn8F0Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/monuments/index.vue?macro=true";
import { default as _91slug_93KNWmDwDvInMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/news/[slug].vue?macro=true";
import { default as indexVssEQoyligMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/news/index.vue?macro=true";
import { default as _91slug_93vwIR2Ws8a4Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/science/[slug].vue?macro=true";
import { default as indexWbTYksXClbMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/read/science/index.vue?macro=true";
import { default as searchTRLo98eiEpMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/search.vue?macro=true";
import { default as _91slug_93QkNFfHvSyGMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/tags/[slug].vue?macro=true";
import { default as _91chapter_933nuZWKx1jNMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue?macro=true";
import { default as _91slug_93gH3Szm08QPMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue?macro=true";
import { default as _91slug_93e47Qd9Bcw6Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue?macro=true";
import { default as _91slug_93bPSIuSZqQYMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/book/[slug].vue?macro=true";
import { default as _91slug_93gCUuG87cNFMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue?macro=true";
import { default as indexD7dQ3ZXSo9Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue?macro=true";
import { default as _91slug_93HU7QVxCqqNMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue?macro=true";
import { default as _91slug_93poGtHSOzHTMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue?macro=true";
import { default as _91category_938pCNnV9sONMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category].vue?macro=true";
import { default as indexAc3noZLrBGMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/index.vue?macro=true";
import { default as _91parent_93YR5Tg1UeEeMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent].vue?macro=true";
import { default as _91slug_93saxwuRPiPJMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/glossary/[slug].vue?macro=true";
import { default as indexIhBfzxlcNDMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher/index.vue?macro=true";
import { default as teacher0kfRFFk1QiMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/teacher.vue?macro=true";
import { default as _91slug_937BOTgnGzdPMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/tests/[slug].vue?macro=true";
import { default as indexdViKrBriGJMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/tests/index.vue?macro=true";
import { default as indexR0HXp55HZcMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/timeline/index.vue?macro=true";
import { default as victory_45dayX4IjVE9WhEMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/victory-day.vue?macro=true";
import { default as _91slug_93rg2mm5k3ppMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/courses/[slug].vue?macro=true";
import { default as indexJPa4HbB9NfMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/courses/index.vue?macro=true";
import { default as _91slug_93lcnNR8JiHzMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/films/[slug].vue?macro=true";
import { default as indexP8NMfwcbH2Meta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/films/index.vue?macro=true";
import { default as _91slug_938fw35urZ6FMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/lectures/[slug].vue?macro=true";
import { default as index2dH4M4s3oTMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/lectures/index.vue?macro=true";
import { default as indexnRmeBTlqHXMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/memdates/index.vue?macro=true";
import { default as _91slug_93fo7VuyNcGOMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/unknown-war/[slug].vue?macro=true";
import { default as indexkpUFugDL8mMeta } from "/home/jetmix/histrf.ru/histrf-client/pages/watch/unknown-war/index.vue?macro=true";
export default [
  {
    name: "_summer",
    path: "/_summer",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/_summer.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/about/index.vue")
  },
  {
    name: "authors-slug",
    path: "/authors/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/authors/[slug].vue")
  },
  {
    name: "authors",
    path: "/authors",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/authors/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    meta: _91slug_93TGmQ4I6LggMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/collections/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/collections/index.vue")
  },
  {
    name: destinationiKE3okghqqMeta?.name,
    path: "/destination",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination.vue"),
    children: [
  {
    name: _91paragraph_93TNrXPPgihYMeta?.name,
    path: ":paragraph()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph].vue"),
    children: [
  {
    name: "destination-paragraph",
    path: "",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/index.vue")
  },
  {
    name: "destination-paragraph-region_article-slug",
    path: "region_article/:slug()",
    meta: _91slug_93n1tuWlebkgMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue")
  },
  {
    name: "destination-paragraph-region_heraldic-slug",
    path: "region_heraldic/:slug()",
    meta: _91slug_93beEx94sPsdMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue")
  },
  {
    name: "destination-paragraph-region_route-slug",
    path: "region_route/:slug()",
    meta: _91slug_93AY45fWNagEMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue")
  }
]
  },
  {
    name: "destination",
    path: "",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/destination/index.vue")
  }
]
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/events.vue")
  },
  {
    name: "gpt-chat",
    path: "/gpt-chat",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/gpt-chat.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgpm3DJvp8sMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/index.vue")
  },
  {
    name: "library",
    path: "/library",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/library.vue")
  },
  {
    name: "listen-courses-slug",
    path: "/listen/courses/:slug()",
    meta: _91slug_939FvNZBudrgMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/listen/courses/[slug].vue")
  },
  {
    name: "listen-courses",
    path: "/listen/courses",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/listen/courses/index.vue")
  },
  {
    name: "listen-lectures-slug",
    path: "/listen/lectures/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/listen/lectures/[slug].vue")
  },
  {
    name: "listen-lectures",
    path: "/listen/lectures",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/listen/lectures/index.vue")
  },
  {
    name: "magazine-article-slug",
    path: "/magazine/article/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/magazine/article/[slug].vue")
  },
  {
    name: "magazine-release-slug",
    path: "/magazine/release/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/magazine/release/[slug].vue")
  },
  {
    name: "myhistory",
    path: "/myhistory",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/myhistory.vue")
  },
  {
    name: "presentation",
    path: "/presentation",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/presentation/index.vue")
  },
  {
    name: "privacy_policy_application",
    path: "/privacy_policy_application",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/privacy_policy_application.vue")
  },
  {
    name: "privacy_policy",
    path: "/privacy_policy",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/privacy_policy.vue")
  },
  {
    name: "profile-account",
    path: "/profile/account",
    meta: accountSBFBZJkYNPMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/profile/account.vue")
  },
  {
    name: "profile-bookmarks",
    path: "/profile/bookmarks",
    meta: bookmarksZUMSmPF6wKMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/profile/bookmarks.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index3Jbt6KkPUSMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/profile/index.vue")
  },
  {
    name: "profile-results",
    path: "/profile/results",
    meta: resultsdKlsa8SGeOMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/profile/results.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/projects/index.vue")
  },
  {
    name: "read-articles-slug",
    path: "/read/articles/:slug()",
    meta: _91slug_93O7q43nFZYWMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/articles/[slug].vue")
  },
  {
    name: "read-articles",
    path: "/read/articles",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/articles/index.vue")
  },
  {
    name: "read-biographies-slug",
    path: "/read/biographies/:slug()",
    meta: _91slug_938YE5rFtCUdMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/biographies/[slug].vue")
  },
  {
    name: "read-biographies",
    path: "/read/biographies",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/biographies/index.vue")
  },
  {
    name: "read-documents-slug-document",
    path: "/read/documents/:slug()/:document()",
    meta: _91document_93VDf7qRJLn9Meta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/documents/[slug]/[document].vue")
  },
  {
    name: "read-documents-slug",
    path: "/read/documents/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/documents/[slug]/index.vue")
  },
  {
    name: "read-documents",
    path: "/read/documents",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/documents/index.vue")
  },
  {
    name: "read-monuments-slug",
    path: "/read/monuments/:slug()",
    meta: _91slug_935TEMa030PLMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/monuments/[slug].vue")
  },
  {
    name: "read-monuments",
    path: "/read/monuments",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/monuments/index.vue")
  },
  {
    name: "read-news-slug",
    path: "/read/news/:slug()",
    meta: _91slug_93KNWmDwDvInMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/news/[slug].vue")
  },
  {
    name: "read-news",
    path: "/read/news",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/news/index.vue")
  },
  {
    name: "read-science-slug",
    path: "/read/science/:slug()",
    meta: _91slug_93vwIR2Ws8a4Meta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/science/[slug].vue")
  },
  {
    name: "read-science",
    path: "/read/science",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/read/science/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchTRLo98eiEpMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/search.vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/tags/[slug].vue")
  },
  {
    name: teacher0kfRFFk1QiMeta?.name,
    path: "/teacher",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher.vue"),
    children: [
  {
    name: _91parent_93YR5Tg1UeEeMeta?.name,
    path: ":parent()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent].vue"),
    children: [
  {
    name: _91category_938pCNnV9sONMeta?.name,
    path: ":category()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category].vue"),
    children: [
  {
    name: "teacher-parent-category-chapter",
    path: ":chapter()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue")
  },
  {
    name: "teacher-parent-category-article-slug",
    path: "article/:slug()",
    meta: _91slug_93gH3Szm08QPMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue")
  },
  {
    name: "teacher-parent-category-audiolecture-slug",
    path: "audiolecture/:slug()",
    meta: _91slug_93e47Qd9Bcw6Meta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue")
  },
  {
    name: "teacher-parent-category-book-slug",
    path: "book/:slug()",
    meta: _91slug_93bPSIuSZqQYMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/book/[slug].vue")
  },
  {
    name: "teacher-parent-category-chapter-slug",
    path: "chapter/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue")
  },
  {
    name: "teacher-parent-category",
    path: "",
    meta: indexD7dQ3ZXSo9Meta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue")
  },
  {
    name: "teacher-parent-category-test-slug",
    path: "test/:slug()",
    meta: _91slug_93HU7QVxCqqNMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue")
  },
  {
    name: "teacher-parent-category-videolecture-slug",
    path: "videolecture/:slug()",
    meta: _91slug_93poGtHSOzHTMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue")
  }
]
  },
  {
    name: "teacher-parent",
    path: "",
    meta: indexAc3noZLrBGMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/[parent]/index.vue")
  }
]
  },
  {
    name: "teacher-glossary-slug",
    path: "glossary/:slug()",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/glossary/[slug].vue")
  },
  {
    name: "teacher",
    path: "",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/teacher/index.vue")
  }
]
  },
  {
    name: "tests-slug",
    path: "/tests/:slug()",
    meta: _91slug_937BOTgnGzdPMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/tests/[slug].vue")
  },
  {
    name: "tests",
    path: "/tests",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/tests/index.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/timeline/index.vue")
  },
  {
    name: "victory-day",
    path: "/victory-day",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/victory-day.vue")
  },
  {
    name: "watch-courses-slug",
    path: "/watch/courses/:slug()",
    meta: _91slug_93rg2mm5k3ppMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/courses/[slug].vue")
  },
  {
    name: "watch-courses",
    path: "/watch/courses",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/courses/index.vue")
  },
  {
    name: "watch-films-slug",
    path: "/watch/films/:slug()",
    meta: _91slug_93lcnNR8JiHzMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/films/[slug].vue")
  },
  {
    name: "watch-films",
    path: "/watch/films",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/films/index.vue")
  },
  {
    name: "watch-lectures-slug",
    path: "/watch/lectures/:slug()",
    meta: _91slug_938fw35urZ6FMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/lectures/[slug].vue")
  },
  {
    name: "watch-lectures",
    path: "/watch/lectures",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/lectures/index.vue")
  },
  {
    name: "watch-memdates",
    path: "/watch/memdates",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/memdates/index.vue")
  },
  {
    name: "watch-unknown-war-slug",
    path: "/watch/unknown-war/:slug()",
    meta: _91slug_93fo7VuyNcGOMeta || {},
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/unknown-war/[slug].vue")
  },
  {
    name: "watch-unknown-war",
    path: "/watch/unknown-war",
    component: () => import("/home/jetmix/histrf.ru/histrf-client/pages/watch/unknown-war/index.vue")
  }
]